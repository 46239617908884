<template>
  <Page color="primary" title="intern.gymkirchenfeld.ch">
    <template slot="before">
      <Modules />
    </template>
    <template v-if="help" slot="help">{{ help }}</template>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import Modules from "@/components/modules/";

export default defineComponent({
  name: "Intern",
  components: { Modules },
  props: ["help"],
  data() {
    return {};
  },
});
</script>
